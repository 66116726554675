/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import toast from 'react-hot-toast';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import {
  orderInfoHandler,
  returnClkEvent,
  cbClkEvent,
  addProduct,
  returnResonsHandler,
  quantityChange,
  stepChange,
  actionChange,
  dateChange,
  addressChange,
  returnHandler,
  checkPincodeServiceability,
  preSignedUrl,
  uploadFileToS3,
  setImages,
  resetImages,
  resetReturnView,
  uploadImageConfig,
  fileUploadCompleted
} from '../../service';

import RequestForm from './forms/request';
import {
  address as addressObj,
  currencyMapper
} from '../../utils/entity_mapping';
import actionMessages from '../../utils/messages';
import PlacedOrder from './placedOrder';
import BankDetailForm from './forms/bankDetail';

const phonePattern = new RegExp(/^\d{10}$/);
const pincodePattern = new RegExp(/^\d{6}$/);
const emailPattern = new RegExp(/^.+@.+\..+$/);

import { strings } from '../../utils/localizedStrings';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const MyLoader = props => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="150" y="15" rx="3" ry="3" width="250" height="12" />
    <rect x="150" y="40" rx="3" ry="3" width="250" height="12" />
    <rect x="10" y="10" width="100" height="120" />
  </ContentLoader>
);

class SelfShipOrder extends Component {
  state = {
    initialValues: { reason: null, comment: '' },
    address: Object.assign(addressObj),
    isSelfShipped: false,
    tempPincodeData: { city: '', state: '', country: '', pincode: '' },
    selfShiptext: ''
  };
  componentDidMount() {
    const {
      user,
      orderInfoHandler,
      location,
      returnResonsHandler,
      orderInfo
    } = this.props;
    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    this.setState({
      selfShiptext: this.props.tracking?.theme?.meta_data?.udf?.filter(
        item => item.key === 'selfShipText'
      )?.[0]?.value
    });
    const query = {
      key: searchParams.get('security_key'),
      waybill: searchParams.get('waybill'),
      orderId: searchParams.get('order_id'),
      mobile: searchParams.get('mobile'),
      domain: user.subdomain
    };
    if (query.orderId) {
      query.orderId = encodeURIComponent(query.orderId);
    }
    if (!orderInfo.loggedUser) {
      orderInfoHandler(query);
    }
    returnResonsHandler(user.subdomain);
  }

  componentWillUnmount = () => {
    this.props.resetReturnView();
  };

  requestReasonChange = e => {
    this.props.uploadImageConfig(e);
  };

  onChange = e => {
    this.setState({
      initialValues: {
        reason: null,
        comment: null
      }
    });
    this.props.resetImages();
    this.props.cbClkEvent(Number(e.target.value));
  };

  backToList = () => {
    this.props.cbClkEvent(this.props.orderInfo.action.activeIndex);
  };

  editRequest = (e, i) => {
    const { products, request } = this.props.orderInfo;
    this.props.cbClkEvent(i);
    const product = products[i];
    if (request.hasOwnProperty(product.id)) {
      this.setState({
        initialValues: {
          reason: request[product.id].reasonId,
          comment: request[product.id].comment
        }
      });
      this.props.resetImages();
      this.props.setImages(request[product.id].files);
    }
  };

  returnClkEvent = () => {
    this.props.returnClkEvent();
  };

  uploadImage = (item, idx) => {
    return this.props.preSignedUrl(idx).then(res => {
      if (res.meta.status === 200) {
        return this.props.uploadFileToS3(idx);
      }
    });
  };

  addProductForReturn = (reason, comment) => {
    const { capture_image } = this.props.tracking.theme.meta_data;
    const { products, action, request } = this.props.orderInfo;
    const itemId = products[action.activeIndex].id;
    if (capture_image && this.props.file.list.length < 1) {
      return toast.error(actionMessages.noImage);
    }
    const promiseArray = this.props.file.list.map(this.uploadImage);
    return Promise.all(promiseArray).then(async res => {
      if (!res?.every(resp => resp?.status === 204)) {
        this.props.fileUploadCompleted();
        toast.error('Image upload failed please try again!', {
          id: 'imageUploadToast'
        });
        return res;
      }

      if (res.length > 0) {
        this.props.fileUploadCompleted();
        await sleep(100);
      } else {
        await sleep(100);
      }
      // const { reason, comment } = this.props.requestForm;
      request[itemId] = Object.assign(request[itemId], {
        reasonId: reason,
        comment,
        isSubmitted: true,
        product_image_link: this.props.file.list
          .map(item => item.s3Url)
          .join(','),
        files: this.props.file.list
      });
      this.props.addProduct(request);
    });
  };

  quantityChange = (e, method) => {
    const { orderInfo, tracking } = this.props;
    if (tracking.theme.meta_data && tracking.theme.meta_data.quantity_lock) {
      return;
    }
    const { products, action, request } = orderInfo;
    // const { products, action, request } = this.props.orderInfo;
    const product = products[action.activeIndex];
    if (request.hasOwnProperty(product.id)) {
      if (
        method === 'add' &&
        request[product.id].quantity < product.eligible_quantity_can_be_return
      ) {
        request[product.id].quantity += 1;
      }
      if (method === 'sub' && request[product.id].quantity > 1) {
        request[product.id].quantity -= 1;
      }
    }
    this.props.quantityChange(request);
  };

  goToStep = (e, current) => {
    const { step } = this.props.orderInfo;
    if (current > step.reached) {
      step.reached = current;
    }
    step.current = current;
    this.props.stepChange(step);
    if (step.current > 1) {
      const exsitingRequests = this.props.orderInfo.request;
      let request = {};
      for (const key in exsitingRequests) {
        if (
          exsitingRequests.hasOwnProperty(key) &&
          exsitingRequests[key].isSubmitted
        ) {
          request[key] = exsitingRequests[key];
        }
      }
      this.props.quantityChange(request);
    }
  };

  backToStep = (e, current) => {
    const { step, action } = this.props.orderInfo;
    step.reached = current;
    step.current = current;
    this.props.stepChange(step);
    if (current < 3) {
      action.address.select = -1;
      action.address.edit = -1;
      action.address.isEditting = false;
      this.props.actionChange(action);
      this.setState({ isSelfShipped: false });
    }
  };

  addressSelection = (e, index) => {
    e.preventDefault();
    const self = this;
    const { action, addresses } = this.props.orderInfo;
    let address = addresses[index];
    // const inProgress = message.loading(actionMessages.pincode.checking, 0);
    const toastId = toast.loading(actionMessages.pincode.checking);
    this.props.checkPincodeServiceability(address.pincode).then(res => {
      const { city, state, country } = res.result.pincode_data;
      if (city) {
        address.city = city;
      }
      if (state) {
        address.state = state;
      }
      if (country) {
        address.country = country;
      }
      if (
        !address.email ||
        !address.address ||
        !address.phone ||
        !address.city ||
        !address.state ||
        !address.country ||
        !address.pincode
      ) {
        // return message.error(actionMessages.addressForm.missingFields, 2.5);
        return toast.error(actionMessages.addressForm.missingFields, {
          id: toastId
        });
      }
      if (res.result.pincode_serviceable) {
        self.setState({ isSelfShipped: false });
        // message.success(actionMessages.pincode.serviceable, 2.5);
        toast.success(actionMessages.pincode.serviceable, { id: toastId });
      } else {
        self.setState({ isSelfShipped: true });
        // action.address.select = -1;
        // this.props.actionChange(action);
        // message.info(actionMessages.pincode.notServiceable, 2.5);
        toast.error(
          this.state.selfShiptext ?? actionMessages.pincode.notServiceable,
          {
            id: toastId
          }
        );
      }
      action.address.select = index;
      this.props.actionChange(action);
    });
  };

  addressEdit = (e, index) => {
    const { action, addresses } = this.props.orderInfo;
    action.address.edit = index;
    action.address.isEditting = true;
    this.props.actionChange(action);
    if ((action, addresses.length === action.address.edit)) {
      addresses[action.address.edit] = Object.assign({
        isSubmitted: false,
        name: '',
        address: '',
        email: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        phone: ''
      });
    }
    this.setState({ address: addresses[action.address.edit] });
    this.props.addressChange(addresses);
  };

  backToAddresses = () => {
    const { action, addresses } = this.props.orderInfo;
    if (addresses[action.address.edit].isSubmitted === false) {
      addresses.splice(action.address.edit, 1);
    }
    action.address.edit = -1;
    action.address.isEditting = false;
    this.props.actionChange(action);
    this.props.addressChange(addresses);
    this.setState({ address: Object.assign(addressObj) });
  };

  pincodeChange = pincode => {
    const { action } = this.props.orderInfo;
    let addresses = this.props.orderInfo.addresses;
    // const { pincode } = this.props.addressForm;
    if (pincodePattern.test(pincode)) {
      this.props.checkPincodeServiceability(pincode).then(res => {
        const { result } = res;
        if (result.pincode_serviceable) {
          let stateAddress = this.state.address;
          let address = addresses[action.address.edit];
          const { city, state, country } = result.pincode_data;
          stateAddress = Object.assign(stateAddress, {
            city: city || '',
            state: state || '',
            country: country || '',
            pincode
          });
          this.setState({ address: stateAddress });
          address = Object.assign(address, { city, state, country, pincode });
          addresses[action.address.edit] = address;
          this.props.addressChange(addresses);
        } else {
          // this.setState({ isSelfShipped: true });
          // message.info(actionMessages.pincode.notServiceable, 2.5);
          toast.error(
            this.state.selfShipText ?? actionMessages.pincode.notServiceable
          );
        }
        this.setState({
          isSelfShipped: result.pincode_serviceable ? false : true
        });
      });
    }
  };

  addressFormSubmit = _address => {
    return sleep(200).then(() => {
      const { addresses, action } = this.props.orderInfo;
      action.address.select = action.address.edit;

      const {
        name,
        email,
        address,
        landmark,
        city,
        state,
        country,
        pincode,
        phone
      } = _address;

      if (!phonePattern.test(phone)) {
        toast.error(actionMessages.phone.blank);
        return;
      }
      if (!pincodePattern.test(pincode)) {
        return toast.error(actionMessages.pincode.blank);
      }
      if (!emailPattern.test(email)) {
        return toast.error(actionMessages.blank.blank);
      }
      this.props.checkPincodeServiceability(pincode).then(res => {
        const { result } = res;
        if (
          !result.pincode_serviceable &&
          this.props.tracking.theme.config &&
          !this.props.tracking.theme.config.self_ship_allowed
        ) {
          toast.error(actionMessages.noShipping);
        } else if (!result.pincode_serviceable) {
          toast.error(
            this.state.selfShipText ?? actionMessages.pincode.notServiceable
          );
        }
        addresses[action.address.edit] = {
          name,
          email,
          address,
          landmark,
          city,
          state,
          country,
          pincode,
          phone,
          isSubmitted: true
        };
        this.props.addressChange(addresses);
        action.address.edit = -1;
        action.address.isEditting = false;
        this.props.actionChange(action);
        this.setState({
          isSelfShipped: result.pincode_serviceable ? false : true
        });
      });
    });
  };

  onCalendarChange = date => {
    this.props.dateChange(date);
  };

  submitReturn = () => {
    const { isSelfShipped } = this.state;
    const {
      request,
      addresses,
      action,
      date,
      shipment_uuid,
      awb,
      reference_number,
      isErrorInCOD,
      bankDetail,
      orderType
    } = this.props.orderInfo;
    let address = {};
    const { meta_data, udfKeys } = this.props.tracking.theme;
    if (addresses.length - 1 >= action.address.select) {
      address = addresses[action.address.select];
    }
    var query = {
      return_info: {
        return_name: address.name || null,
        return_email: address.email || null,
        return_address: address.address || null,
        return_landmark: address.landmark || null,
        return_city: address.city || null,
        return_state: address.state || null,
        return_country: address.country || null,
        return_pin_code: address.pincode || null,
        return_phone_number: address.phone || null,
        return_pickup_date: isSelfShipped ? null : date || null,
        reference_number
      },
      shipment_uuid,
      awb,
      domain: this.props.user.subdomain
    };
    query.item_info = Object.keys(request).map(item => {
      return {
        id: Number(item),
        quantity: request[item].quantity,
        reason_id: request[item].reasonId.value,
        reason_comment: request[item].comment,
        product_image_link: request[item].product_image_link
          ? request[item].product_image_link
          : null
      };
    });

    query.self_shipped = isSelfShipped ? true : false;
    if (isErrorInCOD) {
      if (
        bankDetail.name ||
        bankDetail.accountNumber ||
        bankDetail.ifsccode ||
        bankDetail.bankName ||
        bankDetail.branchName
      ) {
        return toast.error(actionMessages.return.bankDetail);
      }
    } else if (
      orderType === 'COD' &&
      meta_data &&
      meta_data.capture_bank_detail
    ) {
      query.bank_detail = {
        name: bankDetail.name,
        account_number: bankDetail.accountNumber,
        ifsccode: bankDetail.ifsccode,
        bank_name: bankDetail.bankName,
        branch_name: bankDetail.branchName
      };
    }
    const toastId = toast.loading(actionMessages.inProgress);
    this.props.returnHandler(query).then(res => {
      if (res.meta && res.meta.success) {
        toast.success(
          udfKeys?.returnSubmitToast ?? actionMessages.return.submit,
          { id: toastId }
        );
        const itemInfoIds = query.item_info.map(item => item.id);
        this.props.stepChange({ reached: 1, current: 1 });
        this.props.history.push(
          `/returns/status?security_key=${query.shipment_uuid}&waybill=${
            query.awb
          }&item_info_id=${itemInfoIds.join(
            ','
          )}&return_id=${res.result.return_id.join(',')}`
        );
      } else {
        if (res.meta && res.meta.message) {
          toast.error(res.meta.message, { id: toastId });
        } else {
          toast.error(res.message, { id: toastId });
        }
      }
    });
  };

  pushRoute = (e, route) => {
    this.props.history.push(route);
  };

  toggleSelfShipped = () => {
    this.setState({ isSelfShipped: false });
  };

  submitSelfShip = (e, current) => {
    const { orderInfo, tracking } = this.props;
    if (tracking.theme.config && !tracking.theme.config.self_ship_allowed) {
      return toast.error(actionMessages.selfShip);
    }
    const {
      action
      // addresses
    } = orderInfo;
    // commented below code for showing self ship always
    // if (
    //   action.address.isEditting &&
    //   !addresses[action.address.edit].isSubmitted
    // ) {
    //   addresses.splice(action.address.edit, 1);
    // }
    action.address.select = 0;
    action.address.edit = -1;
    action.address.isEditting = false;
    this.props.actionChange(action);
    this.setState({ isSelfShipped: true, address: Object.assign(addressObj) });

    this.goToStep(e, current);
  };

  render() {
    const { orderInfo, reasons, tracking, file, user } = this.props;
    const { lang } = user;
    const { action, request, step, addresses, products } = orderInfo;
    let formData = { reason: null, comment: '' };
    const { isSelfShipped } = this.state;
    if (action.activeIndex !== -1 && products.length > 0) {
      formData = {
        reason: request[products[action.activeIndex].id].reasonId,
        comment: request[products[action.activeIndex].id].comment
      };
    }
    const { config, meta_data } = tracking.theme;
    return (
      <Fragment>
        <div
          className={`text-left main-container ${this.props.user.subdomain}  ${
            step.current > 1 ? 'return-form' : ''
          } bg-white`}
        >
          <div className="container-fluid">
            <div className={`row ${step.current > 1 ? 'show' : 'hide'}`}>
              <div className="col-md-24 col-xs-24 hidden-xs">
                <div className="row">
                  <div
                    className={`col-md-12 step-header ${
                      step.current === 1 ? 'active' : ''
                    }`}
                    onClick={e => this.backToStep(e, 1)}
                  >
                    <p className="heading">Return Summary</p>
                    <p className="sub-heading">
                      {Object.keys(request).length}{' '}
                      {`item${Object.keys(request).length <= 1 ? '' : 's'}`}
                    </p>
                  </div>
                  <div
                    className={`col-md-12 step-header ${
                      step.current === 3 ? 'active' : ''
                    }`}
                    onClick={
                      step.reached > 2 ? e => this.backToStep(e, 3) : null
                    }
                  >
                    <p className="heading">Review</p>
                  </div>
                </div>
              </div>
              <div className="col-md-24 col-xs-24 visible-xs xs-steps-container">
                <div
                  className="xs-display-in-block pull-left"
                  onClick={e => this.goToStep(e, 1)}
                >
                  <span className="icon-arrow_back pull-left fs-20" />
                  <span className="pull-left xs-pd-left-10">Back</span>
                </div>
                <span className="pos-x-center">
                  Step {step.current >= 2 ? 2 : step.current} of 2
                </span>
              </div>
            </div>
            <div
              className={`row row-eq-height return-container ${
                step.current > 1
                  ? 'view-min-ht xs-view-min-ht return-no-footer'
                  : ''
              }`}
            >
              <div
                className={`col-md-16 col-xs-24 order ${
                  step.reached > 1 ? 'col-md-12 eq-design' : 'col-md-offset-4'
                } ${step.current !== 1 ? 'show hidden-xs' : ''}`}
              >
                {action.activeIndex === -1 ? <PlacedOrder /> : null}
                <div className="row">
                  <div className="col-md-24 col-xs-24 mrg-btm-5">
                    <span className="order-number pull-left">
                      Order ID: {orderInfo.reference_number || ' '}
                    </span>
                  </div>
                </div>
                <div className="row item-productInfo">
                  {orderInfo.isFetching ? (
                    <div className="col-md-24 col-xs-24">
                      <section className="content preloader">
                        <MyLoader />
                      </section>
                    </div>
                  ) : (
                    <Fragment>
                      {orderInfo.products.map((product, i) => (
                        <div
                          key={i}
                          className={`col-md-24 col-xs-24 ${
                            orderInfo.action.activeIndex === -1
                              ? 'show'
                              : orderInfo.action.activeIndex === i
                              ? 'show'
                              : 'hide'
                          }`}
                        >
                          <section
                            className={`content ${
                              orderInfo.action.activeIndex === i
                                ? 'bd-top-width-1'
                                : ''
                            } ${
                              request[product.id] &&
                              request[product.id].isSubmitted
                                ? 'active'
                                : ''
                            }`}
                          >
                            <div className="row">
                              <div className="col-md-24 col-xs-24">
                                <div className="item-thumbnail">
                                  <a
                                    href={product.product_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        product.images ||
                                        (user.subdomain === 'truemeds' ||
                                        user.subdomain === 'truemeds-test'
                                          ? '/images/medicine.svg'
                                          : '/images/apparel.svg')
                                      }
                                      alt="product"
                                    />
                                  </a>
                                </div>
                                <div className="item-info">
                                  <div className="item-productName">
                                    {product.description}
                                  </div>
                                  <div className="size-block">
                                    <span className="label">
                                      {strings[lang]?.qty ?? 'Qty'}{' '}
                                    </span>{' '}
                                    <span className="label">
                                      {product.quantity}
                                    </span>
                                  </div>
                                  <div className="size-block">
                                    {currencyMapper(config.country_code)}{' '}
                                    {product.price}
                                  </div>
                                  <div
                                    className={`size-block ${
                                      product.order_cancelled ? 'show' : 'hide'
                                    } txt-red`}
                                  >
                                    Pickup cancelled, place fresh pickup
                                    request.
                                  </div>
                                </div>
                                {product.shipment_eligible_for_return &&
                                product.eligible_quantity_can_be_return > 0 ? (
                                  <div
                                    className={`action editing ${
                                      step.current > 1 ? 'right-m-20' : ''
                                    }`}
                                  >
                                    {action.activeIndex === i ? (
                                      <Fragment>
                                        <h6 className="text-center">
                                          Quantity
                                        </h6>
                                        <div
                                          className={`return-quantity ${
                                            meta_data.quantity_lock
                                              ? 'disable-element'
                                              : ''
                                          }`}
                                        >
                                          <div
                                            className="dec"
                                            onClick={e =>
                                              this.quantityChange(e, 'sub')
                                            }
                                          >
                                            <span className="icon-minus1" />
                                          </div>

                                          <div className="text">
                                            {request[product.id].quantity}
                                          </div>
                                          <div
                                            className="inc"
                                            onClick={e =>
                                              this.quantityChange(e, 'add')
                                            }
                                          >
                                            <span className="icon-plus1" />
                                          </div>
                                        </div>
                                      </Fragment>
                                    ) : request[product.id] &&
                                      request[product.id].isSubmitted ? (
                                      <button
                                        className="ant-button ghost min-width-75 xs-min-width-60"
                                        value={i}
                                        onClick={e => this.editRequest(e, i)}
                                      >
                                        Edit
                                      </button>
                                    ) : (
                                      <div className="inputGroup">
                                        <button
                                          className="ant-button ghost min-width-75 xs-min-width-60"
                                          value={i}
                                          onClick={this.onChange}
                                        >
                                          Return
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </section>
                        </div>
                      ))}
                    </Fragment>
                  )}
                  {step.current === 1 ? (
                    action.canGoToAddress && action.activeIndex === -1 ? (
                      <div className="col-md-24 col-xs-24">
                        <section className="footer">
                          <button
                            className="ant-button full-width"
                            onClick={
                              config && config.self_ship_allowed
                                ? e => this.submitSelfShip(e, 3)
                                : null
                            }
                          >
                            Next
                          </button>
                        </section>
                      </div>
                    ) : null
                  ) : null}
                </div>
                {action.activeIndex > -1 ? (
                  <Fragment>
                    <div className="divider-horizontal mrg-top-20"></div>
                    <h3>Why are you returning the product?</h3>
                    <RequestForm
                      onSubmit={this.addProductForReturn}
                      reasons={reasons}
                      backToList={this.backToList}
                      data={formData}
                      reasonChange={this.requestReasonChange}
                      initialValues={this.state.initialValues}
                      isCaptureImage={orderInfo.isCaptureImage}
                      fileUploadConfig={file}
                    />
                  </Fragment>
                ) : null}
                {step.current !== 1 ? (
                  <div className="inactive-overlay">
                    <div className="edit-button-container">
                      <button
                        className="ant-button"
                        onClick={e => this.backToStep(e, 1)}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className={`col-md-12 col-xs-24 pickup ${
                  step.reached > 1 ? 'show' : 'hide'
                } ${
                  step.current !== 3
                    ? 'hidden-xs'
                    : isSelfShipped
                    ? ''
                    : 'bd-top-color-trans'
                }`}
              >
                <section className="row content-scroll pd-btm-40">
                  {step.current === 3 && isSelfShipped ? (
                    <Fragment>
                      <div className="col-md-24 col-xs-24 mrg-btm-10">
                        <p>
                          We do not have a reverse pickup facility currently. In
                          order to return your product(s), please follow the
                          instructions below:
                        </p>
                        <br />
                        <ul className="bullet-list">
                          <li>
                            Please pack the items securely to prevent any loss
                            or damage during transit. Add a note mentioning your
                            order ID and slip it inside the package.
                          </li>
                          <li>
                            Ship the product(s) back to us on the address
                            mentioned below. Please email us an image of the
                            courier receipt so that we can keep a track of the
                            package as well.
                            <br />
                            <p className="li-ht-24 mrg-top-10">
                              The Souled Store
                            </p>
                            <p className="li-ht-24 mrg-btm-20">
                              F6 - 202, Bhumi World-Industrial Park,
                              <br />
                              Pimplas Village, Mumbai-Nashik Highway,
                              <br />
                              Before Kalyan-Bhiwandi Naka, Opp. Tata Amantra,
                              Pimplas,
                              <br />
                              Thane, Maharashtra - 421302.
                            </p>
                          </li>
                          <li>
                            Once the product reaches our warehouse and the
                            necessary quality checks are performed, the entire
                            amount will be refunded back to your The Souled
                            Store account as Reward Points which you can use to
                            place future orders with us. Additionally, a maximum
                            of Rs. 100 will be refunded to you as Reward Points
                            as well.
                          </li>
                          <li>
                            In the case of apparels, please make sure that the
                            product is in good condition and the tags are intact
                            or the products will not be eligible for return.
                          </li>
                          <li>
                            To maintain the strict hygiene standards of our
                            products, we do not accept returns for masks,
                            boxers, and sweat activation t-shirts.
                          </li>
                        </ul>
                        <p>
                          <strong>Please Note:</strong> We have a 15-day return
                          policy. Your package should reach us within 15 days
                          from the date the order was delivered to you. Packages
                          that reach us after 15 days will not be eligible for
                          return.
                        </p>
                        <p className="li-ht-24 mrg-top-10">
                          We request that you do not use Professional Couriers
                          for self-return as they are not reliable and the
                          package will not be accepted at the warehouse. Please
                          make sure your courier costs do not exceed the amount
                          stipulated above. We recommend using ‘Speed Post’ as
                          your courier service. Speed Post is a Government of
                          India owned entity and has the most widely distributed
                          postal network in India.
                        </p>
                      </div>
                      {meta_data.capture_bank_detail ? (
                        <BankDetailForm />
                      ) : null}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="col-md-24 col-xs-24 mrg-btm-10 visible-xs">
                        Review
                      </div>
                      <div className="col-md-24 col-xs-24 mrg-btm-10">
                        <article className="summary-container">
                          <div className="label">Return Summary</div>
                          <p className="text-gray">
                            {Object.keys(request).length}{' '}
                            {`item${
                              Object.keys(request).length <= 1 ? '' : 's'
                            }`}
                          </p>
                        </article>
                      </div>
                      {action.address.select > -1 && addresses.length > 0 ? (
                        <div className="col-md-24 col-xs-24 mrg-btm-10">
                          <article className="summary-container">
                            <div className="label">
                              {strings[lang]?.pickupAddress ?? 'Pickup Address'}
                            </div>
                            <p className="text-gray">
                              {addresses[action.address.select].name}{' '}
                            </p>
                            <p className="text-gray">
                              {addresses[action.address.select].address}
                            </p>
                          </article>
                        </div>
                      ) : null}
                      <div className="col-md-24 col-xs-24 mrg-btm-10 xs-mrg-btm-50">
                        <article className="datepicker-container">
                          <div className="label text-black">Pickup Date</div>
                          <span
                            className={`${
                              !this.props.orderInfo.date
                                ? 'custom-calendar'
                                : ''
                            }`}
                          >
                            <DatePicker
                              className="pick-date"
                              placeholderText="Pickup Date"
                              onChange={this.onCalendarChange}
                              minDate={new Date(moment().add(1, 'days'))}
                              maxDate={new Date(moment().add(31, 'days'))}
                              selected={this.props.orderInfo.date}
                              dateFormat="dd-MM-yyyy"
                              isClearable={true}
                            />
                          </span>
                        </article>
                      </div>
                    </Fragment>
                  )}
                  <div
                    className={`col-md-24 col-xs-24 proceed-button stick-to-bottom 
                  ${
                    action.address.select < 0 || !orderInfo.date
                      ? isSelfShipped && !orderInfo.isErrorInCOD
                        ? ''
                        : 'disable-element'
                      : ''
                  } ${step.current === 3 ? 'show' : 'hide'}`}
                    onClick={this.submitReturn}
                  >
                    Confirm
                  </div>
                </section>
                {step.current !== 3 ? (
                  <div className="inactive-overlay" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

SelfShipOrder.propTypes = {
  user: PropTypes.object,
  tracking: PropTypes.object,
  orderInfo: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  orderInfoHandler: PropTypes.func,
  returnClkEvent: PropTypes.func,
  cbClkEvent: PropTypes.func,
  addProduct: PropTypes.func,
  returnResonsHandler: PropTypes.func,
  reasons: PropTypes.object,
  quantityChange: PropTypes.func,
  stepChange: PropTypes.func,
  actionChange: PropTypes.func,
  dateChange: PropTypes.func,
  requestForm: PropTypes.object,
  addressForm: PropTypes.object,
  addressChange: PropTypes.func,
  returnHandler: PropTypes.func,
  checkPincodeServiceability: PropTypes.func,
  preSignedUrl: PropTypes.func,
  uploadFileToS3: PropTypes.func,
  file: PropTypes.object,
  setImages: PropTypes.func,
  resetImages: PropTypes.func,
  uploadImageConfig: PropTypes.func,
  resetReturnView: PropTypes.func,
  fileUploadCompleted: PropTypes.func
};

SelfShipOrder.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  reasons: state.reasons,
  file: state.file,
  requestForm: formValueSelector('request')(state, 'reason', 'comment'),
  addressForm: formValueSelector('address')(
    state,
    'name',
    'email',
    'address',
    'landmark',
    'city',
    'state',
    'country',
    'pincode',
    'phone'
  )
});

const mapDispatchToProps = dispatch => ({
  orderInfoHandler: query => dispatch(orderInfoHandler(query)),
  returnClkEvent: () => dispatch(returnClkEvent()),
  cbClkEvent: index => dispatch(cbClkEvent(index)),
  addProduct: query => dispatch(addProduct(query)),
  returnResonsHandler: domain => dispatch(returnResonsHandler(domain)),
  quantityChange: query => dispatch(quantityChange(query)),
  stepChange: step => dispatch(stepChange(step)),
  actionChange: query => dispatch(actionChange(query)),
  dateChange: date => dispatch(dateChange(date)),
  addressChange: addresses => dispatch(addressChange(addresses)),
  returnHandler: query => dispatch(returnHandler(query)),
  checkPincodeServiceability: query =>
    dispatch(checkPincodeServiceability(query)),
  preSignedUrl: idx => dispatch(preSignedUrl(idx)),
  uploadFileToS3: idx => dispatch(uploadFileToS3(idx)),
  setImages: data => dispatch(setImages(data)),
  resetImages: () => dispatch(resetImages()),
  uploadImageConfig: data => dispatch(uploadImageConfig(data)),
  resetReturnView: () => dispatch(resetReturnView()),
  fileUploadCompleted: () => dispatch(fileUploadCompleted())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelfShipOrder)
);
